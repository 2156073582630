import { removeIndexFromLabel } from "../../Utils";
import { isTable, isTableValid } from "./TableAttributesUtil";
import gtConfig from '../../config.js';


export function dataAreValidToConfirm(extractedValues){
    for (const [key, data] of extractedValues) {
        const values = data[0];
        if (Array.isArray(values) && !isTableValidToBeConfirm((values))) {
            return false;
        } else {
            if ( !values || values === ''){
                return false
            }
        }
    }
    return true;
}


export function removeEmptyScheduleRows(extractedValues){
    const newMap = new Map();
    for (let attribue of extractedValues) {
        const isScheduleAttribute = Array.isArray(attribue) && attribue.length > 0 && isTable(attribue[1]);
       
        if ( isScheduleAttribute) {
            const scheduleLength = attribue[1][0].length;
            const newValues = [attribue[1][0][0]]; // add the header
            for (let i = 1; i < scheduleLength; i++){
                const isRowEmpty = attribue[1][0][i].slice(0, -1).every(arr => arr[0].length === 0 ||  arr[0] === '' )
                if (!isRowEmpty){
                    newValues.push(attribue[1][0][i]);
                }
            }
            if (newValues.length === 1){ // It just has the header
                continue;
            }
            attribue[1][0] = newValues;
            newMap.set(attribue[0], attribue[1]);
        } else {
            newMap.set(attribue[0], attribue[1]);
        }
    }

    return newMap;
}

/*
* Remove all the attributes that has ('Not Found') value with empty/null Context.
*/
export function removeInvalidAttributes(extractedValues){
    const newMap = new Map();
    for (let attribue of extractedValues){
        if ( emptySingleAttribue(attribue)){
           continue
        }
        newMap.set(attribue[0], attribue[1]);
    }

    return newMap;

    function emptySingleAttribue(attribue) {
        const contextIndex = 9;
        return Array.isArray(attribue) && attribue.length > 1 
               && Array.isArray(attribue[1]) 
               && attribue[1].length > 9
               && gtConfig.attribute_empty_values.includes(attribue[1][0]) 
               && gtConfig.invalid_context.includes(attribue[1][contextIndex]);
    }
}

export function isTableValidToBeConfirm(values){
    return isTableValid(values);
}

export function unconfirmRevertAllAttributes(attributeKey, confirmedKeys, extractedValuesOriginRef){
    const attribute = removeIndexFromLabel(attributeKey);
    const originKeys= Array.from(extractedValuesOriginRef.keys());
    const keys = originKeys.filter(key => key.startsWith(attribute));
    for (let key of keys){
        unconfirmRevertAttributes(key, confirmedKeys, extractedValuesOriginRef)
    }
}

export function unconfirmRevertAttributes(attributeKey, confirmedKeys, extractedValuesOriginRef){
    const attributeValues = extractedValuesOriginRef.get(attributeKey);
    if (isTable(attributeValues)){
       for ( let i = 1; i < attributeValues[0].length; i++){
        const index = attributeValues[0][i][attributeValues[0][i].length - 1][0];
            if (confirmedKeys.has(index)){
                confirmedKeys.delete(index);
            }
       }
       confirmedKeys.delete(attributeKey);
    } else {
        confirmedKeys.delete(attributeKey);
    }
}
