import React, {useState, useEffect} from 'react';
import { Modal, Button, Box, Typography, Grid } from "@mui/material";
import { modalAttributePopupStyle } from "../styles";
import {
  DataGrid,
  GridToolbarExportContainer,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridToolbarContainer
} from '@mui/x-data-grid';
import { styled } from '@mui/material';
import fetchDataFileUrl from './../services';
import { useOktaAuth } from '@okta/okta-react';
import gtConfig from './../config.js';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import {useTheme} from "@mui/material/styles";


const csvOptions = { delimiter: ',' };

function CustomToolbar() {
  return (
    <GridToolbarContainer>
        <CustomExportButton />
    </GridToolbarContainer>
  );
}

function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCustomeCsvExportMenuItem />
    </GridToolbarExportContainer>
  );
}

const StyledDataGrid = styled(DataGrid)({
  fontSize: '12px', // Adjust the font size as needed
});

function GridCustomeCsvExportMenuItem() {
  const apiRef = useGridApiContext();
  return (
    <MenuItem
      onClick={() => {
        const csvContent = getCSV(apiRef);
        const blob = new Blob([csvContent], {
          type: 'text/csv',
        });
        exportBlob(blob, 'DataGrid_demo.csv');
      }}
    >
      Export CSV
    </MenuItem>
  );
}

function customeFlatter(values){
  let result = [];
  for (let value of values){
    result.push(value.flat().join(' '));
  }

  return result.join('||');

}

function getCSV(apiRef) {
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  const headerRow = visibleColumnsField.map((field) => field).join(csvOptions.delimiter);
  const dataRows = gridFilteredSortedRowIdsSelector(apiRef).map((id) => {
    const rowData = visibleColumnsField.map((field) => {
      const cellValue = apiRef.current.getCellParams(id, field).value;
      return Array.isArray(cellValue) ? customeFlatter(cellValue) : cellValue;
    });
    return rowData.join(csvOptions.delimiter);
  });

  return `${headerRow}\n${dataRows.join('\n')}`;
}


const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};


const AttributesPopupModal = ({isOpen, onClose, file_type, category, attribute_name, selectedFile} ) => {
const { authState, oktaAuth } = useOktaAuth();
const [jsonValue, setJsonValue] = useState('');
const [data, setData] = useState(null);

const theme = useTheme();

const getAttributeName = (attribute_name) => {
  const attName = attribute_name.replace(/\[.+?]/, '');
  return attName;
}
let url_attribute_name = '';

  useEffect(() => {

    const fetchData = async () => {
      try {
        url_attribute_name = getAttributeName(attribute_name);
        const attribute_url_request = gtConfig.ground_truth_back_end.attributePopupUrl + "?file_type=" + file_type + "&attribute_name=" + url_attribute_name + "&category=" + category
        const attributeData = await fetchDataFileUrl(attribute_url_request, authState, oktaAuth);

        const audit_url_request = gtConfig.ground_truth_back_end.standardizedValuesUrl + "?file_type=" + file_type + "&attribute_name=" + url_attribute_name
        const auditData = await fetchDataFileUrl(audit_url_request, authState, oktaAuth);
      
        // Extract rows from the JSON data dynamically
        const rows = [];
        Object.keys(attributeData.response).forEach((file) => {
          Object.keys(attributeData.response[file]).forEach((attr) => {
            const fileName = file.split('/').pop();
            rows.push({ file: fileName.replace(".json", ""), value: attributeData.response[file][attr], standardized: auditData[fileName],id: attr });
          });
        });

        setData(rows);
        setJsonValue(JSON.stringify(attributeData, null, 4));

      } catch (error) {
        console.error('Error fetching Attribute Data:', error);
      }
    }
    fetchData();
  }, []);

const columns = [
  { field: 'file', headerName: 'File', width: 280 },
  { 
    field: 'value', 
    headerName: 'Attribute Value', 
    width: 400,
    renderCell: (params) => {
      const items = Array.isArray(params.value) ? params.value : [params.value];
      return (
        <Box>
          {items.map((item, index) => (
               <div key={index}>{ item != null ? item.toString() : ''}</div>
          ))}
        </Box>
      );
    },
  },
  { field: 'standardized',
    headerName: 'Standardized Values',
    width: 1000,
    renderCell: (params) => {
      const items = Array.isArray(params.value) ? params.value : [params.value];
      return (
        <Box>
          {items.map((item, index) => (
            <div key={index}>{ item != null ? item.toString() : ''}</div>
          ))}
        </Box>
      );
    },
 },
  { field: 'id', headerName: 'id', width: 430, },
];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="List of Attribute Values Across All Files"
      aria-describedby="List of Attribute Values Across All Files"
    >
       <Box sx={{ ...modalAttributePopupStyle, backgroundColor: theme.palette.background.paper}}>
      
        <Box display="flex"  alignItems="center"  gap={2}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary}}>Attribute: </Typography>
          <Typography variant='h7' sx={{ color: theme.palette.text.primary}}>{getAttributeName(attribute_name)}</Typography><br />
        </Box>

          <Box style={{ height: '500px', width: '100%', overflow: 'auto' }} >
          { data ?
           <StyledDataGrid
            rows={data}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
            }}
            disablePagination
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.id}
            columnVisibilityModel={{
              // hide the 'id' column, showing in tooltip instead
              id: false,
            }}
            
          /> :  <LinearProgress color="primary" title='Loading Attributes Data'/>
        }
        </Box>

        <Grid container spacing={0}>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
         
              style={{ marginTop: "10px", marginLeft: '10px' }}
              onClick={onClose}>Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    
    </Modal>
  );
};

export default AttributesPopupModal;
