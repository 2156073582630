import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faUnlock, faUpload } from '@fortawesome/free-solid-svg-icons';
import gtConfig from '../../config.js';
import { useOktaAuth } from '@okta/okta-react';


function ActionMenu({setFileUploadModalOpen, setValidationIssueModalOpen, setLockDocumentModalOpen, handleActionClose, isPathFinderEnable}) {
    const { authState, oktaAuth } = useOktaAuth();

    const theme = useTheme();
const isUserEligibleToUploadFile = () => {
    const email = authState.idToken.claims.email;
    const userId = email.replace('@spglobal.com', '');
    if (process.env.REACT_APP_ENV === 'development'){
        return true;
    }

    if (process.env.REACT_APP_ENV === 'qa' && gtConfig.qa_file_upload_authorized_users.includes(userId)) {
        return true;
    }

    if (process.env.REACT_APP_ENV === 'production' && gtConfig.prod_file_upload_authorized_users.includes(userId)) {
        return true;
    }

    return false;
}


    function uploadFile(){
        setFileUploadModalOpen(true); 
        handleActionClose()
    }

    function showValidationIssues(){
        setValidationIssueModalOpen(true); 
        handleActionClose()
    }

    function showUnlockDocument(){
        setLockDocumentModalOpen(true); 
        handleActionClose()
    }
  
    
    return (
        <Box sx={{ width: '100%'}}>
            <nav aria-label="main mailbox folders">
            <List>
                {isPathFinderEnable && 
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={showValidationIssues}
                    >
                        <ListItemIcon>
                        <FontAwesomeIcon icon={faTasks}/>
                        </ListItemIcon>
                        <ListItemText primary="Validation Issues" />
                    </ListItemButton>
                </ListItem>
                }
                {isPathFinderEnable && 
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={showUnlockDocument}
                    >
                        <ListItemIcon>
                        <FontAwesomeIcon icon={faUnlock} />
                        </ListItemIcon>
                        <ListItemText primary="Lock/Unlock Documents" />
                    </ListItemButton>
                </ListItem>
                }
               { isUserEligibleToUploadFile() && <ListItem disablePadding>
                    <ListItemButton
                        onClick={uploadFile}
                    >
                        <ListItemIcon>
                        <FontAwesomeIcon icon={faUpload} />
                        </ListItemIcon>
                        <ListItemText primary="File Upload" />
                    </ListItemButton>
                </ListItem>
               }
            </List>
            </nav>
      </Box>
    );
}

export default ActionMenu