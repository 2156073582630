import { Box, Button, Modal, Typography } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { validationIssueModalStyle } from "../../styles.js";
import { AgGridReact } from 'ag-grid-react';
import Tooltip from '@mui/material/Tooltip';
import gtConfig from '../../config.js';
import { getPathfinderJobs } from '../../services.jsx';
import {useTheme} from "@mui/material/styles";
import { useOktaAuth } from '@okta/okta-react';
import { useThemeContext } from '../../theme/ThemeContextProvider.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const ValidationIssueModal = ({ isOpen, onClose, setSelectedDocument, files} ) => {
  const { themeMode, toggleColorMode } = useThemeContext();
  const [aggridTheme, setAggridTheme] = useState(null);

  const theme = useTheme();

  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const [validationIssueData, setValidationIssueData] = useState([]);
  const [validationIssueColDefs,] = useState([
    {
        field: "attributes.filename",
        headerName: "Document",
        width: 400,
        editable: false,
        cellStyle: { textAlign: "left" },
        cellRenderer: (params) => {
          return (<Button variant="text" style={{ cursor: 'pointer' }}  onClick={() => handleClickDocument(params)}> {params.value} </Button>
          );
        }
    },
    {
      field: "attributes.filetype",
      headerName: "Type",
      width: 200,
      editable: false,
      cellStyle: { textAlign: "left" },
    },
    {
      field: "username",
      headerName: "Locked By",
      width: 250,
      editable: false,
      cellStyle: { textAlign: "left" },
    },
    {
      field: "taskOwner",
      headerName: "Task Owner",
      width: 250,
      editable: false,
      cellStyle: { textAlign: "left" },
    },
    {
      field: "streamName",
      headerName: "Stream Name",
      width: 350,
      editable: false,
      cellStyle: { textAlign: "left" },
  },
  {
    field: "attributes.extractionTimestamp",
    headerName: "Extraction Timestamp",
    width: 350,
    editable: false,
    cellStyle: { textAlign: "left" },
    sort: 'desc'
  },
]);

useEffect(() => {
  setAggridTheme(themeMode === 'light' ? 'ag-theme-quartz ag-theme-sp-light-default' : 'ag-theme-quartz-dark ag-theme-sp-dark-default');
}, [themeMode]);

const handleClickDocument = (params) => {
  setSelectedDocument(params.value,params.data.attributes.filetype);
  onClose();
};
    
  
useEffect(() => {
  const fetchValidationIssueData = async () => {
    try {
      setLoading(true);
      const url = gtConfig.ground_truth_back_end.getJob + "?is_validation=true";
      const result =  await getPathfinderJobs(authState, oktaAuth, url);
      if (result && result.response && result.response.list){
        const validationIssues = result.response.list;

        const filesMap = {}
        Object.entries(files).forEach(([_, { modified_date, file_name}]) => {
          const extraction_modified_date = modified_date.find(item => item.hasOwnProperty('extraction'))?.['extraction'];
          filesMap[file_name] = extraction_modified_date;
        });

        for (let validationIssue of validationIssues){
            validationIssue.attributes['extractionTimestamp'] = filesMap[validationIssue.attributes.filename];
        }
        setValidationIssueData(validationIssues);

      }
    } catch (error) {
      console.error('Error fetching Validation issue Data:', error);
    } finally {
      setLoading(false);
    }
  }
  fetchValidationIssueData();
}, []);

 
  return (
    <Modal 
        open={isOpen} 
        onClose={onClose}
        aria-labelledby="Validation Issues"
        aria-describedby="Validation Issues"
        >
          <Box sx={{...validationIssueModalStyle, backgroundColor: theme.palette.background.paper}}>
            <Box   sx={{
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 1,
                    borderColor: theme.palette.divider,
                    backgroundColor: theme.palette.background.paper,
                    // backgroundColor: '#eeeeee',
                }}
            >
                <Typography sx={{color: theme.palette.text.primary}}>
                  Validation Issues 
                </Typography>
                <Tooltip title="Close">
                  <FontAwesomeIcon icon={faWindowClose} sx={{ color: theme.palette.text.primary}} onClick={onClose}  size="2x"/>
                </Tooltip>
            </Box>
            <Box
              sx={{
                  width: "98%",
                  height: "90%",
                  display: "flex",
                  borderRadius: "5px",
                  border: "1px solid",
                  flexDirection: "column",
                  borderColor: '#babfc7',
                  // backgroundColor: '#eeeeee',
                }}
            >
                <AgGridReact
                    rowData={validationIssueData}
                    columnDefs={validationIssueColDefs}
                    className={aggridTheme}
                    loading={loading}
                />
            </Box>
         </Box>
    </Modal>
  );
};

export default ValidationIssueModal;
