import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";


export default function ConfirmAllDialog({acceptConfirmAll, confirmAllDialogOpen, setConfirmAllDialogOpen }){

 
    return (
        <Dialog
            open={confirmAllDialogOpen}
            keepMounted
            onClose={ () => setConfirmAllDialogOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={false}
        >
            <DialogTitle>
                {
                    <span>
                         <Typography variant='h6' color='error'>Please note that 'Confirm All' will remove all "Not Found" attributes which have "empty/null" Context.</Typography>
                        <br />
                        <Typography variant='body'>Are you sure you want to continue?</Typography>
                    </span>
                }

            </DialogTitle>
            <DialogActions>
                <Button sx={{ color: "red" }} onClick={ () => setConfirmAllDialogOpen(false)}>
                    Cancel
                </Button>
                <Button
                    sx={{ color: "green" }}
                    onClick={acceptConfirmAll}
                >
                    Confirm
                </Button>
            </DialogActions>
    </Dialog>
    )
}
