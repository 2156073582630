import React, { useState } from 'react';

import "@ag-grid-community/styles/ag-grid.css";  /* Core grid CSS */
import "@ag-grid-community/styles/ag-theme-quartz.css";  /* quartz theme CSS */
import "./theme/ag-theme-sp.scss"
import './App.css';
import {LicenseManager} from "@ag-grid-enterprise/core";
import {ModuleRegistry} from "@ag-grid-community/core";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';

import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import Routes from './components/Routes';
import config from './config';

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-052141}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Markit_Group_Limited}_is_granted_a_{Multiple_Applications}_Developer_License_for_{4}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_February_2025}____[v3]_[01]_MTczODM2ODAwMDAwMA==039ebdf8b7978e225f45d93507bd5762');
ModuleRegistry.registerModules([RichSelectModule, ExcelExportModule, MenuModule, SetFilterModule, ClientSideRowModelModule ]);

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
    const navigate = useNavigate();
    const restoreOriginalUri = (_oktaAuth,  originalUri) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };


    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routes/>
        </Security>
    );
}

export default App;
