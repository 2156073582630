import React, { useState, useCallback } from 'react';
import gtConfig from "../../config";
import { Menu, useTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText';
import { ListItemIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import CopyAllIcon from '@mui/icons-material/CopyAll';

function SelectComponent  ({
    id, 
    extractionKey,
    attribute, 
    mainDivClass, 
    fileType,
    initialData,
    onSelectChange,
    selectOptionStyle,
    editMode,
    isConfirmed,
    cellIndex,
    rowIndex,
    cell,
    type,
    disabled,
    onMenuCopyToAllClick
}) {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const theme = useTheme();

    const handleContextMenu = (event) => {
        event.preventDefault();
        setMenuAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null); // Close the menu
    };

    const handleMenuCopyToAllClick = (event) => {
        if ( type === 'grid') {
          onMenuCopyToAllClick(cellIndex, rowIndex, cell);
        }
        handleCloseMenu();
    }

    const getValues = useCallback(() => {
        const schedulePreDefinedTypes = gtConfig.schedule_pre_defined_types[fileType];
        const scheduleName = extractionKey.substring(0, extractionKey.indexOf('['));
        const typeMap = schedulePreDefinedTypes[scheduleName];
        const values = typeMap[attribute];
        return values;
    },[]);

    const getStyle = useCallback (() => {
        const schedulePreDefinedTypes = gtConfig.schedule_pre_defined_types[fileType];
        const scheduleName = extractionKey.substring(0, extractionKey.indexOf('['));
        const typeMap = schedulePreDefinedTypes[scheduleName];
        const values = typeMap[attribute];
        let updatedStyle = {...selectOptionStyle}
        updatedStyle.backgroundColor = theme.palette.background.paper;
        updatedStyle.color = theme.palette.text.secondary;
        if (Array.from((values)).includes(initialData) || initialData == null || initialData.toString() === "_NF" || initialData.toString() === "Not Found")
            updatedStyle.color = theme.palette.text.secondary;
        else
            updatedStyle.color = "red";
        return updatedStyle;
    },[theme]);


    return (
        <>
        <select id={id} 
            className={mainDivClass}
            style={getStyle()}
            value={initialData} 
            onChange={onSelectChange}
            title={initialData}
            disabled={!editMode || isConfirmed(extractionKey)}
            onContextMenu={type === 'grid' ? handleContextMenu : undefined}
            >

        <option key='_NF' value='_NF'>_NF</option>
        {getValues().map((val, index) => {
            return <option key={val +index}  value={val}>{val}</option>
        })}
    </select>
    {!disabled && <Menu
      anchorEl={menuAnchor}
      open={Boolean(menuAnchor)}
      onClose={handleCloseMenu}
    >
     { type === 'grid' && <MenuItem onClick={handleMenuCopyToAllClick}>
        <ListItemIcon >
              <CopyAllIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2" fontSize="small">
            Copy to All
          </Typography>
        </ListItemText>
      </MenuItem>
    }
    </Menu>
    }
    </>
    )
}

export default SelectComponent