import { Typography, useTheme } from '@mui/material';
import React from 'react';

const ConfirmationStatsPopup = ({ totalFiles, totalFilesConfirmed, totalFields, totalFieldsConfirmed }) => {

    const calculatePercentage = (count, total) => ((count / total) * 100).toFixed(2);
    const theme = useTheme();

    return (
        <div style={{marginTop: '20px'}}>
            <Typography variant='h6' sx={{ color: theme.palette.text.secondary}} >Confirmation Stats</Typography>
            <table align='center' border="0" cellSpacing="5" cellPadding="3" style={{fontSize:"12px"}}>
                <tbody>
                <tr>
                    <th style={{ color: theme.palette.text.primary}}>Type</th>
                    <th style={{ color: theme.palette.text.primary}}>Total</th>
                    <th style={{ color: theme.palette.text.primary}}>Confirmed</th>
                    <th style={{ color: theme.palette.text.primary}}>% Confirmed</th>
                </tr>
                <tr>
                <td style={{ color: theme.palette.text.secondary}}>Files</td>
                    <td style={{ color: theme.palette.text.secondary}}>{totalFiles}</td>
                    <td style={{ color: theme.palette.text.secondary}}>{totalFilesConfirmed}</td>
                    <td style={{ color: theme.palette.text.secondary}}>{calculatePercentage(totalFilesConfirmed, totalFiles)}%</td>
                </tr>
                <tr>
                <td style={{ color: theme.palette.text.secondary}}>Fields</td>
                    <td style={{ color: theme.palette.text.secondary}}>{totalFields}</td>
                    <td style={{ color: theme.palette.text.secondary}}>{totalFieldsConfirmed}</td>
                    <td style={{ color: theme.palette.text.secondary}}>{calculatePercentage(totalFieldsConfirmed, totalFields)}%</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ConfirmationStatsPopup;