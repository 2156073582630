import React, { useState, useEffect } from 'react';
import { Grid, Typography } from "@mui/material";

const DistributionContent = (extractedValues) => {
    const [jsonValue, setJsonValue] = useState('');
   
    useEffect(() => {
        const data = Object.fromEntries(extractedValues.extractedValues);
        setJsonValue(JSON.stringify(data, null, 4));
    }, [extractedValues]); 


    return (
        <Grid container>  
              {extractedValues.selectedFile && (
                <Grid item xs={12} >
                    <Typography variant="h6" sx={{mb:2}} mt={2} fontWeight="bold">Distribution</Typography>
                   
                    <Typography variant="h6" sx={{mb:2, textDecoration: 'underline'}} mt={2} fontWeight="bold">{extractedValues.selectedFile}</Typography>
                   
                </Grid>
                )}

             {(
                <Grid item  xs={12}>
                    <textarea   aria-label="Distribution data"
                        placeholder="Large JSON data..."
                        defaultValue={jsonValue} 
                        style={{ width: '90%', minHeight: 500 }}
                    ></textarea>
                </Grid>
             )}   
        </Grid>
    )
}


export default DistributionContent;