import { removeIndexFromLabel } from "../../Utils";
import { unconfirmRevertAllAttributes, unconfirmRevertAttributes } from "./ConfirmAttributes";


export function addAttributeKeyToChangedAttributeRef(attributeKey, changedAttributes){
    const key = removeIndexFromLabel(attributeKey);
    if (changedAttributes.has(key)){
        const currentKeys = changedAttributes.get(key);
        const newKeys = currentKeys.add(attributeKey);
        changedAttributes.set(key, newKeys);
    } else {
        const attributeSet = new Set();
        attributeSet.add(attributeKey);
        changedAttributes.set(key, attributeSet);
    }
}

export function deleteAttributeKeyFromChangedAttributeRef(key, changedAttributes){
    changedAttributes.delete(key);
};


export function ifAttributeKeyIncludeInChangedAttribute(attributeKey, changedAttributes){
    const key = removeIndexFromLabel(attributeKey);
    return changedAttributes.has(key);
}

export function ifAttributeKeyIncludeInChangedAttributeItem(attributeKey, changedAttributes){
    const key = removeIndexFromLabel(attributeKey);

    return !changedAttributes.has(key) || ! changedAttributes.get(key).has(attributeKey);
}

export function revertAttributeChanges(attributeKey, changedAttributes, confirmedKeys, extractedValuesOriginRef){
    const key = removeIndexFromLabel(attributeKey);
    if (changedAttributes.has(key)){
        const newList = Array.from(changedAttributes.get(key)).filter( item => item !== attributeKey);
        if (newList.length === 0){
            changedAttributes.delete(key);
        } else {
            changedAttributes.set(key, new Set(newList));
        }

        unconfirmRevertAttributes(attributeKey, confirmedKeys, extractedValuesOriginRef);
    }
}

export function revertAllAttributeChanges(attributeKey, changedAttributes, confirmedKeys, extractedValuesOriginRef){
    const key = removeIndexFromLabel(attributeKey);
    changedAttributes.delete(key);

    unconfirmRevertAllAttributes(attributeKey, confirmedKeys, extractedValuesOriginRef);
}
