import React, { useEffect, useState } from 'react';
import gtConfig from './../../config.js';
import { useOktaAuth } from '@okta/okta-react';
import {useTheme} from "@mui/material/styles";
import fetchDataFileUrl from './../../services';
import { Button, Chip, Modal, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { auditModalStyle } from "../../styles.js";
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




const AuditModal = ({ id, fileType, onClose, isOpen} ) => {
  
    const [data, setData] = useState([]);
    const [detailedView, setDetailedView] = useState(false);
    const { authState, oktaAuth } = useOktaAuth();

    const theme = useTheme();

    useEffect(() => {
      const fetchData = async () => {
          try {
              const url_request = gtConfig.ground_truth_back_end.timeMachineUrl + "?file_type="+ fileType + "&file_name="+ id
              const auditData = await fetchDataFileUrl(url_request, authState, oktaAuth);
              setData(auditData);
          } catch (error) {
              console.error('Error fetching Time Machine data:', error);
          }
      };

      fetchData();
  }, []);

    useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const jsonHandler = (value, index, prevValue) => {
    try {
      if (value.charAt(0) !== '{') {
        return value.replaceAll('_', '..\n_');
      }
      const jsonObject = JSON.parse(value.replaceAll(/'/g, '"').replaceAll('None', 'null'));

      // Format JSON as key-value pairs on separate lines
      const formattedJson = Object.entries(jsonObject)
        .map(([key, val]) => `${key}: ${val}`)
        .join('\n');

      return formattedJson; //formattedJson === prevValue ? 'no change' : formattedJson;
    } catch (error) {
      //console.error('Error parsing JSON:', error);
      return value; //value === prevValue ? 'no change' : value;
    }
  };

  const isInAcceptedColumns = (data, detailedView, index) => {
    if (detailedView ){
      return  true;
    } else {
      //Index of desired columns to show in summary mode
      const idexOfField = data[0].indexOf("Field");
      const idexOfExtractedValues = data[0].indexOf("Extracted Values"); 
      const idexOfStandardizedValues = data[0].indexOf("Standardized Values"); 

      const filteredColumns= [idexOfField, idexOfExtractedValues, idexOfStandardizedValues];
      return filteredColumns.includes(index);
    }
  }
 
 
  return (
    <>
      <Modal 
          open={isOpen} 
          onClose={onClose}
          aria-labelledby="Validation Issues"
          aria-describedby="Validation Issues"
          >
         <Box sx={{...auditModalStyle}}>
             <Box className="audit-popup-content" sx={{backgroundColor: theme.palette.background.paper}}>
                {/* <span className="close" onClick={onClose}  sx={{color: theme.palette.text.primary}}>&times;</span> */}
                {/* <Typography sx={{ color: theme.palette.text.primary}}>Time Machine for ID: {id}    */}
                <Box
                    sx={{
                        display: "flex",
                        gap: "10px",
                        // justifyContent: "space-between",
                        alignItems: "center",
                        padding: 1,
                    }}
                    >
                    <Box sx={{ display: "flex", justifyContent: 'left', alignItems: 'center', minWidth: '700px'}}>
                        <Typography  sx={{color: theme.palette.text.secondary}}>Time Machine</Typography>
                        <Chip label={id} variant="outlined"  sx={{marginLeft: '10px', gap: '0px'}}/>
                        <Button 
                            variant='contained' 
                            onClick={() => setDetailedView( !detailedView)} 
                            style={{marginLeft:'50px'}}
                            >
                            {detailedView ? 'Summary View' : 'Detailed View'}
                        </Button>
                    </Box>
        
                    <Box display="flex" justifyContent="flex-end" width="100%"   color="primary">
                        <Tooltip title="Close"   color="primary">
                            <FontAwesomeIcon icon={faWindowClose} onClick={onClose} size="2x" color={theme.palette.text.secondary}/>
                        </Tooltip>
                    </Box>
                </Box>    
                { detailedView &&  Object.values(data).length === 0  &&
                    <Typography variant='body1' sx={{ color: theme.palette.text.primary}}> There is no data for  {id} </Typography>
                }

                { data.length > 0 &&
                <table>
                <thead>
                    <tr style={{backgroundColor: theme.palette.background.paper}}>
                    {data[0].map((header, index) => (
                        isInAcceptedColumns(data, detailedView, index) && (
                        <th key={header} style={{backgroundColor: theme.palette.background.paper}}>
                            <Typography variant='body1' sx={{ color: theme.palette.text.primary}}>{header}</Typography>
                        </th>
                        )
                    ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                    rowIndex !==0 && <tr key={rowIndex}>
                        {Object.entries(row).map(([key, value], colIndex) => (
                        (
                            isInAcceptedColumns(data, detailedView, colIndex) && 
                            <td key={colIndex} className="transition-cell" style={{backgroundColor: theme.palette.background.paper}}>
                            <pre>
                                <Typography variant='caption' sx={{ color: theme.palette.text.primary}}>
                                    {jsonHandler(value, rowIndex, rowIndex > 0 ? data[rowIndex - 1][key] : '')}
                                </Typography>
                            </pre>
                            </td>
                        )
                        ))}
                    </tr>
                    ))}
                </tbody>
                </table>
            }
        </Box>
       </Box>
      </Modal>
    </>
  );
};

export default AuditModal;
