import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import axios from "axios";
import gtConfig from "../../config.js";
import { Box, Dialog, DialogActions, DialogTitle, Typography, Modal, useTheme, LinearProgress } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useOktaAuth } from '@okta/okta-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { uploadFileModalStyle, validationIssueModalStyle } from "../../styles.js";
import { fetchFileUploadRedirectUrl } from "../../services.jsx";

const FileUploadModal = ({ isOpen, onClose} ) => {
  
  const { authState, oktaAuth } = useOktaAuth();

  const [uploadedFile, setUploadedFile] = useState(null);
  
  const [fileType, setFileType] = useState(gtConfig.upload_filetypes?.[0] ?? '');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [showProgressBar, setShowProgressBar] = useState(false);

  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);

  const theme = useTheme();

  const handleFileUpload = (event) => {
    setUploadedFile(event.target.files[0]);
    event.target.value = '';
    setSubmitDialogOpen(true);
  };

  const handleFileTypeChange = (event) => {
    console.log(event.target.value);
    setFileType(event.target.value);
  };

  function showSnackBar(msg, severity){
    setSnackBarMsg(msg);
    setSnackBarSeverity(severity);
    handleOpenSnackBar();
  }


  const handleCloseSnackBar = () => {
      setOpenSnackBar(false);
  };

  const handleOpenSnackBar = () => {
      setOpenSnackBar(true);
  };

  const postFile = async () => {
    setSubmitDialogOpen(false);
    setShowProgressBar(true);
       
    if (uploadedFile == null) {
      setShowProgressBar(false);
      showSnackBar(`A file was not chosen. Please upload and try again."`, 'error');
      console.log("File is null");
      return
    } 
    
    if (fileType === null || fileType === "") {
      setShowProgressBar(false);
      showSnackBar(`A File Type was not specified.`, 'error');
      console.log("fileType is null or empty");
      return 
    } 

    
    if (!uploadedFile.type || gtConfig.uploadFileValidFormats.includes(uploadedFile.type) === false) {
      setShowProgressBar(false);
      showSnackBar(`The chosen file is not a valid format. Current file format: ${uploadedFile.type} `, 'error');
      console.log("The chosen file is not valid.");
      return 
    } 
      
    const fileSizeInMB = (uploadedFile.size / (1024 * 1024)).toFixed(2); // Convert size to MB
    console.log(`File size: ${fileSizeInMB} MB`);

    if (fileSizeInMB > gtConfig.file_upload_filesize_limit_mb) {
      showSnackBar(`File size shoud not exceed ${gtConfig.file_upload_filesize_limit_mb} GB. Current file Size: ${fileSizeInMB} MB`, 'warning');
      setShowProgressBar(false);
      return;
    }

    let redirectValueUrl = '';

    try{
      const formData = new FormData();
      formData.append("file", uploadedFile);     
          
      const fileRedirectUrl = gtConfig.ground_truth_back_end.signedUploadFileUrl + "?file_type=" + fileType + "&file_name=" + uploadedFile.name;
      let response  =  fetchFileUploadRedirectUrl(fileRedirectUrl, authState, oktaAuth);

      redirectValueUrl = await response;

    } catch (error) {
      setShowProgressBar(false);
      console.error('Error fetching file upload redirect url:', error);
      showSnackBar(error, 'error');
      return
    }

    await axios.put(redirectValueUrl, uploadedFile, {
      headers: {
        'Content-Type':uploadedFile.type,
      },
    }).then((response) => {
              console.log(response);
              showSnackBar("File uploaded successfully", 'success');
              setUploadedFile(null);
            })
            .catch((error) => {
              console.error(error);
              if ( error.response && error.response.data.errorMessage) {
                showSnackBar(error.response.data.errorMessage, 'error');
              } else {
                showSnackBar(`An error occurred while uploading the file.${error}`, 'error');
              }
            }).finally(() => {
              setUploadedFile(null);
              setShowProgressBar(false);
            });

  };
 
  return (
    <>
        <Modal 
          open={isOpen} 
          onClose={onClose}
          aria-labelledby="Validation Issues"
          aria-describedby="Validation Issues"
        >
            <Box sx={{...uploadFileModalStyle,  backgroundColor: theme.palette.background.paper}}>
                {showProgressBar && <LinearProgress color="primary" title='Uploading File'/>}
                <Snackbar
                    open={openSnackBar}
                    onClose={handleCloseSnackBar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Top center position
                    autoHideDuration={3000} // Auto hide after 3 seconds
                >
                    <Alert
                        onClose={handleCloseSnackBar}
                        message="Snackbar from right div"
                        severity={snackBarSeverity}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackBarMsg}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={submitDialogOpen}
                    keepMounted
                    onClose={ () => setSubmitDialogOpen(false)}
                    aria-describedby="alert-dialog-slide-description"
                    maxWidth={false}
                >
                    <DialogTitle>
                        {
                            <span>
                             Submit Following Document:
                             <br />
                             <br />
                             <Typography><strong>Name:</strong> {uploadedFile?.name} </Typography>
                             <Typography><strong>Type:</strong> {fileType}</Typography>
                            </span>
                        }

                    </DialogTitle>
                    <DialogActions>
                    <Button sx={{ color: "red" }} onClick={ () => setSubmitDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        sx={{ color: "green" }}
                        onClick={postFile}
                    >
                        Submit
                    </Button>
                    </DialogActions>
                </Dialog>
            <Box
                sx={{
                // width: "100vw",
                paddingTop: "20px",
                display: "flex",
                // justifyContent: "center",
                }}
            >
                <Box sx={{ margin:"10px"}}>
                    <Box sx={{marginBottom: "20px"}}>
                        <Typography color="error"><strong>Note:</strong> Uploading Document Will Automatically Trigger The Extraction</Typography>
                    </Box>
                    <Box  sx={{display: "flex", width: "270px"}}>
                        <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">File Type</InputLabel>
                        <Select
                            labelId="fileType-label"
                            id="fileType-select"
                            value={fileType}
                            label="File Type"
                            onChange={handleFileTypeChange}
                            sx={{
                            width: "200px",
                            height: "40px",
                            }}
                        >
                        {gtConfig.upload_filetypes.map((type, index) => (
                            <MenuItem key={type} sx={{ fontSize: "20px" }} value={type}>
                            {type}
                            </MenuItem>
                        ))}
                        </Select>
                        </FormControl>
                        <input 
                            accept="*"
                            style={{ display: 'none', width: '0' }}
                            id="upload-button-file"
                            type="file"
                            onChange={handleFileUpload}
                        />
                        <label htmlFor="upload-button-file"  style={{height:'40px'}}>
                        <Button variant="contained" component="span" 
                            disabled={showProgressBar}
                            style={{height:'40px', marginLeft: '1px'}}>
                            <FontAwesomeIcon icon={faUpload} />
                        </Button>
                        </label>
                    </Box>
                </Box>
            </Box>
        </Box>
      
      </Modal>
    </>
  );
};

export default FileUploadModal;
