import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import Home from '../Home';
import { RequiredAuth } from './SecureRoute';
import Loading from './Loading';
import Profile from '../components/Profile';


const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" exact={true} element={<Home/>}/>
            <Route path="/:platform/:doc_type/:documentName/:mode" exact={true}  element={<Home/>} />
            <Route path="login/callback" element={<LoginCallback loadingElement={<Loading/>}/>}/>
            <Route path="/profile" element={<RequiredAuth/>}>
                <Route path="" element={<Profile/>}/>
            </Route>
        </Routes>
    );
};

export default AppRoutes;
